<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form action="#" @submit.prevent="submit">
          <div class="form-body">
            <h3 class="card-title">Tanggapan Disposisi</h3>
            <hr>

            <div class="row">
                <label>Tanggapan Surat</label>
                <div class="col-md-12">
                    <wysiwyg v-model="myHTML"/>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <label>File Lampiran</label>
                    <Upload v-model="payload.file" is-file name="file"/>
              </div>
            </div>

          </div>

          <hr class="m-t-40">

          <div class="form-actions">
            <button type="submit" class="btn btn-info" @click="setApproval(0)" title="Save">
              <i class="fa fa-check"></i> Save
            </button> &nbsp;
            <!-- <button type="submit" class="btn btn-success" @click="setApproval(1)">
              <i class="mdi mdi-send"></i> Send
            </button> &nbsp; -->
            <button type="button" @click="goBack" class="btn btn-inverse" title="Cancel">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "../../../components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "../../../components/input/upload.vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import store from "../../../store";

export default {
  components: {
    Input,
    Datepicker,
    RotateSquare5,
    Upload,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.dispositionIn;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidSifatSurat() {
      return (
        this.dropdownSifatSurat.isTouched &&
        this.dropdownSifatSurat.value.length === 0
      );
    },
    isInvalidKecepatanSurat() {
      return (
        this.dropdownKecepatanSurat.isTouched &&
        this.dropdownKecepatanSurat.value.length === 0
      );
    },
    isInvalidLampiran() {
      return (
        this.dropdownLampiran.isTouched &&
        this.dropdownLampiran.value.length === 0
      );
    },
    isInvalidJenisSurat() {
      return (
        this.dropdownJenisSurat.isTouched &&
        this.dropdownJenisSurat.value.length === 0
      );
    },
    isInvalidTipeAsalSurat() {
      return (
        this.dropdownTipeAsalSurat.isTouched &&
        this.dropdownTipeAsalSurat.value.length === 0
      );
    },
    isInvalidAsalSurat() {
      return (
        this.dropdownAsalSurat.isTouched &&
        this.dropdownAsalSurat.value.length === 0
      );
    },
    isInvalidKodeSurat() {
      return (
        this.dropdownKodeSurat.isTouched &&
        this.dropdownKodeSurat.value.length === 0
      );
    },
    isInvalidKeteranganSurat() {
      return (
        this.dropdownKeteranganSurat.isTouched &&
        this.dropdownKeteranganSurat.value.length === 0
      );
    },
    isInvalidRedaksi() {
      return (
        this.dropdownRedaksi.isTouched &&
        this.dropdownRedaksi.value.length === 0
      );
    },
    isInvalidUnitKerja() {
      return (
        this.dropdownUnitKerja.isTouched &&
        this.dropdownUnitKerja.value.length === 0
      );
    }
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable"
      ],
      payload: {
        agenda: "",
        nomorsurat: "",
        sifat: [],
        kecrat: [],
        tanggal_surat: "",
        tanggal_diterima: "",
        lampiran: [],
        jenis: [],
        perihal: "",
        tas: [],
        asrat: [],
        kodrat: [],
        ketrat: [],
        redaksi: [],
        unitkerja: [],
        file: "",
        jumlah: 0,
        persetujuan: 0
      },
      dropdownSifatSurat: {
        isTouched: false,
        urgencyList: [],
        value: [],
        isLoadingSifatSurat: false
      },
      dropdownKecepatanSurat: {
        isTouched: false,
        kecratList: [],
        value: [],
        isLoadingKecepatanSurat: false
      },
      dropdownLampiran: {
        isTouched: false,
        lampiranList: [],
        value: [],
        isLoadingJenisLampiran: false
      },
      dropdownJenisSurat: {
        isTouched: false,
        categoryList: [],
        value: [],
        isLoadingJenisSurat: false
      },
      dropdownTipeAsalSurat: {
        isTouched: false,
        tasList: [],
        value: [],
        isLoadingTipeAsalSurat: false
      },
      dropdownAsalSurat: {
        isTouched: false,
        asratList: [],
        value: [],
        isLoadingAsalSurat: false
      },
      dropdownKodeSurat: {
        isTouched: false,
        kodratList: [],
        value: [],
        isLoadingKodeSurat: false
      },
      dropdownKeteranganSurat: {
        isTouched: false,
        ketratList: [],
        value: [],
        isLoadingKeteranganSurat: false
      },
      dropdownRedaksi: {
        isTouched: false,
        redaksiList: [],
        value: [],
        isLoadingKeteranganSurat: false
      },
      dropdownUnitKerja: {
        isTouched: false,
        unitkerjaList: [],
        value: [],
        isLoadingUnitKerja: false
      },
      myHTML: "",
      myFile: [],
      isDisabled: false
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
  },

  methods: {
    setApproval(value) {
      this.payload.persetujuan = value;
    },
    getAllReference() {
      var query = "";
      this.sifatSurat(query);
      this.kecepatanSurat(query);
      this.jenisLampiran(query);
      this.jenisSurat(query);
      this.tipeAsalSurat(query);
      this.asalSurat(query);
      this.kodeSurat(query);
      this.keteranganSurat(query);
      this.redaksi(query);
      this.unitKerja(query);
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/document_in/${this.$route.params.id}`).then(response => {
            const state = {
              loaded: true
            };
            this.$store.commit("dispositionIn/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("dispositionIn/onResponse");
          axios.get(`/document_in/new`).then(response => {
            this.payload.agenda = response.data.data.agenda_number;
            this.payload.tanggal_surat = new Date();
            this.payload.tanggal_diterima = new Date();
          });
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        unitkerja: data.receiver,
        agenda: data.agenda_number,
        tanggal_surat: new Date(data.tanggal_surat),
        tanggal_diterima: new Date(data.tanggal_diterima),
        sifat: data.document_sifat,
        jenis: data.document_jenis,
        perihal: data.subject,
        nama: data.document_name,
        nomorsurat: data.nomorsurat,
        myHTML: data.description,
        file: data.file,
        jumlah: data.jumlah,
        lampiran: data.document_lampiran,
        asrat: data.document_asrat,
        kodrat: data.document_kodrat,
        tas: data.document_tas,
        kecrat: data.document_kecrat,
        ketrat: data.document_ketrat,
        redaksi: data.document_redaksi
      };
      this.dropdownSifatSurat.value = data.document_asrat;
      this.dropdownKecepatanSurat.value = data.document_kecrat;
      this.dropdownLampiran.value = data.document_lampiran;
      this.dropdownJenisSurat.value = data.document_jenis;
      this.dropdownTipeAsalSurat.value = data.document_tas;
      this.dropdownAsalSurat.value = data.document_asrat;
      this.dropdownKodeSurat.value = data.document_kodrat;
      this.dropdownKeteranganSurat.value = data.document_ketrat;
      this.dropdownRedaksi.value = data.document_redaksi;
      this.dropdownUnitKerja.value = data.receiver;
      this.myHTML = data.description;
    },
    goBack() {
      this.$store.dispatch("dispositionIn/onCancel");
    },
    submit() {
      if (
        this.dropdownSifatSurat.value.length === 0 &&
        this.dropdownKecepatanSurat.value.length === 0 &&
        this.dropdownLampiran.value.length === 0 &&
        this.dropdownJenisSurat.value.length === 0 &&
        this.dropdownTipeAsalSurat.value.length === 0 &&
        this.dropdownAsalSurat.value.length === 0 &&
        this.dropdownKodeSurat.value.length === 0 &&
        this.dropdownKeteranganSurat.value.length === 0 &&
        this.dropdownRedaksi.value.length === 0 &&
        this.dropdownUnitKerja.value.length === 0
      ) {
        this.dropdownSifatSurat.isTouched = true;
        this.dropdownKecepatanSurat.isTouched = true;
        this.dropdownLampiran.isTouched = true;
        this.dropdownJenisSurat.isTouched = true;
        this.dropdownTipeAsalSurat.isTouched = true;
        this.dropdownAsalSurat.isTouched = true;
        this.dropdownKodeSurat.isTouched = true;
        this.dropdownKeteranganSurat.isTouched = true;
        this.dropdownRedaksi.isTouched = true;
        this.dropdownUnitKerja.isTouched = true;
        window.scrollTo(0, 0);
      }
      if (this.payload.file) {
        this.myFile = this.payload.file.split(",");
      }
      const payload = {
        agenda: this.payload.agenda,
        nomorsurat: this.payload.nomorsurat,
        document_kecrat: this.payload.kecrat,
        document_sifat: this.payload.sifat,
        tanggal_surat: this.payload.tanggal_surat.toISOString().slice(0, 10),
        tanggal_diterima: this.payload.tanggal_diterima
          .toISOString()
          .slice(0, 10),
        document_lampiran: this.payload.lampiran,
        document_jenis: this.payload.jenis,
        perihal: this.payload.perihal,
        document_tas: this.payload.tas,
        document_asrat: this.payload.asrat,
        document_kodrat: this.payload.kodrat,
        document_ketrat: this.payload.ketrat,
        document_redaksi: this.payload.redaksi,
        receiver: this.payload.unitkerja,
        file: this.myFile[1],
        jumlah: this.payload.jumlah,
        approval: this.payload.persetujuan
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (
          success &&
          !this.isInvalidSifatSurat &&
          !this.isInvalidKecepatanSurat &&
          !this.isInvalidLampiran &&
          !this.isInvalidJenisSurat &&
          !this.isInvalidTipeAsalSurat &&
          !this.isInvalidAsalSurat &&
          !this.isInvalidKeteranganSurat &&
          !this.isInvalidRedaksi &&
          !this.isInvalidUnitKerja
        ) {
          if (this.editMode) {
            this.$store.dispatch("dispositionIn/submitEdit", {
              data,
              id: this.$route.params.id
            });
          } else {
            this.$store.dispatch("dispositionIn/submitAdd", data);
          }
        }
      });
    },
    clearAll() {
      this.payload.kepada = [];
    },
    asyncFind(query) {
      this.dropdownTujuan.isLoadingUserFind = true;
      axios
        .post(`users/findUserTo`, { search: query })
        .then(res => {
          this.dropdownTujuan.userList = res.data.data;
          this.dropdownTujuan.isLoadingUserFind = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    sifatSurat(query) {
      this.dropdownSifatSurat.isLoadingSifatSurat = true;
      axios
        .get(`document_urgencies/list?s=${query}`)
        .then(res => {
          this.dropdownSifatSurat.urgencyList = res.data.items;
          this.dropdownSifatSurat.isLoadingSifatSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    kecepatanSurat(query) {
      this.dropdownKecepatanSurat.isLoadingKecepatanSurat = true;
      axios
        .get(`document_speeds/list?s=${query}`)
        .then(res => {
          this.dropdownKecepatanSurat.kecratList = res.data.items;
          this.dropdownKecepatanSurat.isLoadingKecepatanSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    jenisLampiran(query) {
      this.dropdownLampiran.isLoadingJenisLampiran = true;
      axios
        .get(`attachment_types/list?s=${query}`)
        .then(res => {
          this.dropdownLampiran.lampiranList = res.data.items;
          this.dropdownLampiran.isLoadingJenisLampiran = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    jenisSurat(query) {
      this.dropdownJenisSurat.isLoadingJenisSurat = true;
      axios
        .get(`document_categories/list?s=${query}`)
        .then(res => {
          this.dropdownJenisSurat.categoryList = res.data.items;
          this.dropdownJenisSurat.isLoadingJenisSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    tipeAsalSurat(query) {
      this.dropdownTipeAsalSurat.isLoadingTipeAsalSurat = true;
      axios
        .get(`typeSurat/list?s=${query}`)
        .then(res => {
          this.dropdownTipeAsalSurat.tasList = res.data.items;
          this.dropdownTipeAsalSurat.isLoadingTipeAsalSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    asalSurat(query) {
      this.dropdownAsalSurat.isLoadingAsalSurat = true;
      axios
        .get(`asalSurat/list?s=${query}`)
        .then(res => {
          this.dropdownAsalSurat.asratList = res.data.items;
          this.dropdownAsalSurat.isLoadingAsalSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    kodeSurat(query) {
      this.dropdownKodeSurat.isLoadingKodeSurat = true;
      axios
        .get(`mail_code/list?s=${query}`)
        .then(res => {
          this.dropdownKodeSurat.kodratList = res.data.items;
          this.dropdownKodeSurat.isLoadingKodeSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    keteranganSurat(query) {
      this.dropdownKeteranganSurat.isLoadingKeteranganSurat = true;
      axios
        .get(`description_mail/list?s=${query}`)
        .then(res => {
          this.dropdownKeteranganSurat.ketratList = res.data.items;
          this.dropdownKeteranganSurat.isLoadingKeteranganSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    redaksi(query) {
      this.dropdownRedaksi.isLoadingRedaksi = true;
      axios
        .get(`redaksi/list?s=${query}`)
        .then(res => {
          this.dropdownRedaksi.redaksiList = res.data.items;
          this.dropdownRedaksi.isLoadingRedaksi = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    unitKerja(query) {
      this.dropdownUnitKerja.isLoadingUnitKerja = true;
      axios
        .get(`units/list?s=${query}`)
        .then(res => {
          this.dropdownUnitKerja.unitkerjaList = res.data.items;
          this.dropdownUnitKerja.isLoadingUnitKerja = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    onTouchSifatSurat() {
      this.dropdownSifatSurat.isTouched = true;
    },
    onTouchKecepatanSurat() {
      this.dropdownKecepatanSurat.isTouched = true;
    },
    onTouchLampiran() {
      this.dropdownLampiran.isTouched = true;
    },
    onTouchJenisSurat() {
      this.dropdownJenisSurat.isTouched = true;
    },
    onTouchTipeAsalSurat() {
      this.dropdownTipeAsalSurat.isTouched = true;
    },
    onTouchAsalSurat() {
      this.dropdownAsalSurat.isTouched = true;
    },
    onTouchKodeSurat() {
      this.dropdownKodeSurat.isTouched = true;
    },
    onTouchKeteranganSurat() {
      this.dropdownKeteranganSurat.isTouched = true;
    },
    onTouchRedaksi() {
      this.dropdownRedaksi.isTouched = true;
    },
    onTouchUnitKerja() {
      this.dropdownUnitKerja.isTouched = true;
    },
    onChangeSifatSurat(value) {
      this.dropdownSifatSurat.value = value;
    },
    onChangeKecepatanSurat(value) {
      this.dropdownKecepatanSurat.value = value;
    },
    onChangeLampiran(value) {
      this.dropdownLampiran.value = value;
    },
    onChangeJenisSurat(value) {
      this.dropdownJenisSurat.value = value;
    },
    onChangeTipeAsalSurat(value) {
      this.dropdownTipeAsalSurat.value = value;
    },
    onChangeAsalSurat(value) {
      this.dropdownAsalSurat.value = value;
    },
    onChangeKodeSurat(value) {
      this.dropdownKodeSurat.value = value;
    },
    onChangeKeteranganSurat(value) {
      this.dropdownKeteranganSurat.value = value;
    },
    onChangeRedaksi(value) {
      this.dropdownRedaksi.value = value;
    },
    onChangeUnitKerja(value) {
      this.dropdownUnitKerja.value = value;
    },
    nomorDokumen(value) {
      this.payload.nomor_dokumen = value.document_number;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>
<style lang="scss">
.vdp-datepicker {
    &.fullscreen-when-on-mobile {
        @media (max-width: 767px) {
            position: static;
            .vdp-datepicker__calendar {
                position: fixed;
                top: 50%;
                left: 5%;
                margin-top: -25%;
                width: 90%;
            }
        }
    }
}
</style>
